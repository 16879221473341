import { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { CreateStream, GetStream, UpdateStream, UpdateStreamContent, GetStreamContent,ConvertUnixTimestampToDateString } from "../utilities/calls";
import { AiOutlineFile } from "react-icons/ai";
import { renderField, handleAccordionClick, createFormDataForSelectedFile } from "../utilities/functions";
import Tags from "./Tags";
import CopyToClipboard from "./CopyToClipboard";
import { FaFileDownload } from "react-icons/fa";
import * as dayjs from "dayjs";


export default function StreamDetails() {
    const { id } = useParams();
    const [stream, setStream] = useState({ Name: "", Description: "" });
    const [sent, setSent] = useState(true);
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [customTags, setCustomTags] = useState([])
    const [formData, setFormData] = useState();
    let { state } = useLocation();


    useEffect(() => {
        if (id !== undefined) {
            getStreamDetails(id);
        }

    }, []);

    const getStreamDetails = (id) => {
        GetStream(id).then((resp) => {
            let Tags = resp.tags;
            if (Tags.Name) {
                resp.Name = Tags.Name.Value;
                delete Tags.Name;
            }
            setCustomTags(Tags);
            setStream(resp);

        }).catch((err) => { console.log('Error getting stream', id) })
    } 

    const handleInputChange = (e) => {

        const { name, value } = e.target;
        setStream((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));

        setSent(false);
        setShowAlert(false);
    };
    const addCustomTag = (tag, key) => {
        var tagname = key ? key : tag.Name;
        setCustomTags((prevValues) => ({
            ...prevValues, [tagname]: tag
        }))
        setSent(false);
        setShowAlert(false);
    }

    const removeCustomTag = (tagName) => {
        var newMap = { ...customTags };
        delete newMap[tagName]
        setCustomTags({ ...newMap });
        setSent(false);
        setShowAlert(false);
    }

    const handleFileUploadChange = (e) => {
        let formdata = createFormDataForSelectedFile(e);
        setFormData(formdata)
        setSent(false);
        setShowAlert(false);
    }
    const handleDownload = (id) => {
        GetStreamContent(id).then(resp => {
            const blob = new Blob([resp.data], { type: resp.headers['Content-Type'] });
            const filename = stream?.tags?.__SYS_JW_FileName?.Value ? stream?.tags?.__SYS_JW_FileName?.Value : 'downloaded-file';
            // Create a temporary link element to download the file
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = filename;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }).catch(err => {
            console.log('Error in downloading content', err);
            setAlertText("Error in downloading the document.");
            setShowAlert(true);
        })
    }
    const processForm = () => {
        var mergedData = { Description: stream.Description }
        mergedData.tags = { ...mergedData.tags, ...customTags }
        mergedData.tags.Name = {
            Name: "Name",
            Value: stream.Name,
            Private: false,
            Required: false,
            Editable: false
        }
        if (formData) {
            mergedData.tags.__SYS_JW_Type = {
                Name: "Type",
                Value: formData.get('type'),
                Private: false,
                Required: false,
                Editable: false
            }
            mergedData.tags.__SYS_JW_FileName = {
                Name: "FileName",
                Value: formData.get('name'),
                Private: false,
                Required: false,
                Editable: false
            }
            formData.delete('name');
            formData.delete('type');
        }
        if (id) {
            UpdateStream(id, mergedData).then(resp => {
                if (formData) {
                    //FOR S3 FORMDATA DOESN't WORK WE HAVE TO UPLOAD ONLY THE FILE.
                    UpdateStreamContent(id, formData.get('file')).then(resp => {
                        setSent(true);
                        setShowAlert(true);
                        setAlertText("saved successfully!");
                        getStreamDetails(id);
                    }).catch(err => console.log('Error in uploading file', err));
                } else {
                    setSent(true);
                    setShowAlert(true);
                    setAlertText("saved successfully!");
                    getStreamDetails(id);
                }
            }).catch(err => console.log('Error in creating a document', err))
        } else {
            CreateStream(mergedData).then(stream => {
                if (formData) {
                    //FOR S3 FORMDATA DOESN't WORK WE HAVE TO UPLOAD ONLY THE FILE.
                    UpdateStreamContent(stream.ID, formData.get('file')).then(resp => {
                        setSent(true);
                        setShowAlert(true);
                        setAlertText("saved successfully!");
                        getStreamDetails(stream.ID);
                    }).catch(err => console.log('Error in uploading file', err));
                } else {
                    setSent(true);
                    setShowAlert(true);
                    setAlertText("saved successfully!");
                    getStreamDetails(stream.ID);
                }
            }).catch(err => console.log('Error in creating a stream', err))
        }
    }

    return <div style={{ marginTop: "20px", marginLeft: "20px" }}>
        <div className="mx-auto max-w-screen-lg form-container p-5">
            <div>
                <h1 className="form-label">
                    <AiOutlineFile className="inline-icon" />
                    <span>{id ? "Update Document" : "Add Document"}</span>
                </h1>
                {id ? <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-between"><span className="text-xs md:font-normal text-gray-200" id="docid">{id}<CopyToClipboard elId="docid" /></span><span className="md:items-end md:text-right text-xs italic">{"Last update on " + dayjs(ConvertUnixTimestampToDateString(stream.updated)).format("MMM D, YYYY")}</span></div>: <></>}
            </div>
            <div id="accordion-collapse" data-accordion="collapse" className="p-5">
                <h2 id="accordion-collapse-heading-1">
                    <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-b-0 gap-3" data-accordion-target="accordion-collapse-body-1" onClick={handleAccordionClick}>
                        <span>Document Details </span>
                        <svg data-accordion-icon class="rotate-180 w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                        </svg>
                    </button>
                </h2>
                <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                    <div class="p-5 border border-grey-700">
                        <fieldset>
                            {renderField("Name", "Name", "alphanumeric", stream.Name, handleInputChange)}
                            {renderField("Description", "Description", "alphanumeric", stream.Description, handleInputChange)}

                            {stream?.tags?.__SYS_JW_FileName?.Value ? <div className="grid grid-cols-12">
                                <label className="col-span-12 form-label" htmlFor={id}>
                                    Document:<button formMethod="dialog" formTarget="top" id="download" onClick={() => handleDownload(stream.ID)} className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-1 ms-1">
                                    <FaFileDownload className="inline-icon" style={{ height: "1rem", width: "1rem" }} /> {stream?.tags?.__SYS_JW_FileName?.Value || "Download"}
                                </button>
                                </label>   </div>: <></>}
                            {renderField(id ? "Replace Content from" : "Upload Content from", "File", "fileupload", "", handleFileUploadChange)}

                        </fieldset>
                    </div>
                </div>
                <h2 id="accordion-collapse-heading-3">
                    <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border gap-3" data-accordion-target="accordion-collapse-body-3" onClick={handleAccordionClick}>
                        <span>More Details </span>
                        <svg data-accordion-icon class="rotate-180 w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                        </svg>
                    </button>
                </h2>
                <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                    <div class="p-5 border border-grey-700">
                        <fieldset >
                            <div className="py-5"><Tags isReadOnly={false} saveFn={addCustomTag} tags={customTags} deleteFn={removeCustomTag} /></div>
                        </fieldset>
                    </div>

                </div>


            </div>
            <section className="flex flex-start gap-2 py-2">
                <button style={{ width: "fit-content", display: sent ? "none" : "block", }} formMethod="dialog" formTarget="top" type="submit" onClick={processForm} id="btn-address-add-submit" form="form-address-new" className="button-style positive-action">
                    {id ? "UPDATE" : "ADD"}
                </button>

                {!sent ? (
                    <Link className="  button-style" to={state?.previousPage ? state.previousPage : "/documents"} >Cancel</Link>
                ) : (
                    <Link className="  button-style" to={state?.previousPage ? state.previousPage : "/documents"}>Close</Link>
                )}
                <div class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative" role="alert" style={{ display: showAlert ? "block" : "none" }}>
                    <span class="text-sm">{alertText} </span>
                </div>
            </section>
        </div>
    </div>

}