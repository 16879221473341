import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DeleteStream, GetStreamContent, GetStreams, GetStream, ConvertUnixTimestampToDateString } from "../utilities/calls";
import { AiOutlineFileAdd } from "react-icons/ai";
import { BsFillTrash3Fill } from "react-icons/bs";
import CopyToClipboard from "./CopyToClipboard";
import DeleteModal from "./DeleteModal";
import { displayDate } from "../utilities/functions";
import { FaFileDownload } from "react-icons/fa";

export default function StreamList() {
    const [streams, setStreams] = useState([]);
    const [streamDetails, setStreamDetails] = useState({});
    const [showDeleteDlg, setShowDeleteDlg] = useState();
    const [confirmationText, setConfirmationText] = useState();
    const [currId, setCurrId] = useState();
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();


    useEffect(() => {
        loadStreams();
    }, [])

    const loadStreams = () => {
        GetStreams().then(resp => {
            console.log(resp)
            setStreams(resp)
        }).catch(err => console.log('Error in fetch streams', err));
    }

    useEffect(() => {
        streams.forEach(stream => {
            if (!streamDetails[stream.ID]) {
                GetStream(stream.ID).then(resp => {
                    setStreamDetails((streamDetails) => ({ ...streamDetails, [stream.ID]: resp }))
                }).catch(err => console.log('Error getting stream details', err))
            }

        })
    }, [streams]);

    const handleDeleteClick = (id) => {
        setCurrId(id);
        setConfirmationText("Are you certain you wish to delete this document?");
        setShowDeleteDlg(true);
    }

    const del = () => {
        setShowDeleteDlg(false);

        DeleteStream(currId).then(() => {
            setShowAlert(true);
            setAlertText("Deleted successfully");
            setStreams([]);
            loadStreams();
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
        }).catch(err => {
            setAlertText("Error deleting the document.");
            setShowAlert(true);
            console.log('err', err)
        })
    }

    const handleDownload = (id) => {
        GetStream(id).then(stream => {
            console.log(stream)
            GetStreamContent(id).then(resp => {
                const blob = new Blob([resp.data], { type: resp.headers['Content-Type'] });
                const filename = stream?.tags?.__SYS_JW_FileName?.Value ? stream?.tags?.__SYS_JW_FileName?.Value : 'downloaded-file';
                // Create a temporary link element to download the file
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = filename;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }).catch(err => {
                console.log('Error in downloading content', err);
                setAlertText("Error in downloading the document.");
                setShowAlert(true);
            })
        }).catch(err => {
            console.log('Error in downloading content', err);
            setAlertText("Error in downloading the document.");
            setShowAlert(true);
        })
    }

    return <div className="px-4 py-3 max-w-screen-xl mx-auto items-center">
        <h1 className="p-5 bold-text-input">{streams?.length} Documents</h1>
        <DeleteModal confirmationText={confirmationText} deleteLabel="Delete" onDeleteFn={del} onCancelFn={() => { setShowDeleteDlg(false) }} show={showDeleteDlg} />
        <Link className="" to={{ pathname: "/documentdetails/" }} state={{}}
        >
            <button className="button-style hover:bg-555">
                <AiOutlineFileAdd className="inline-icon" />
                Add Document
            </button>
        </Link>
        <div
            class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
            role="alert"
            style={{ display: showAlert ? "block" : "none" }}
        >
            <span class="text-sm">{alertText} </span>
        </div>
        <div class="w-full p-4 mt-2 border rounded-lg shadow border-gray-700">
            {streams && streams.length > 0 ?
                <ul class="w-full divide-y divide-gray-700">
                    {streams.map((stream, index) => {
                        return <li class="pt-1 pb-3 sm:pb-4">
                            <div class="flex justify-between items-center space-x-4 rtl:space-x-reverse">
                                <p class="text-base font-semibold truncate text-blue-400">
                                    <Link to={`/documentdetails/${stream.ID}`}>
                                        {`${streamDetails[stream.ID]?.tags?.Name?.Value || "No Name"}`}</Link>
                                </p>
                                <div class="inline-flex items-center text-white">
                                    <button formMethod="dialog" formTarget="top" id="deleteTemplate" onClick={() => handleDeleteClick(stream.ID)} className="bg-slate-333 py-1 hover:bg-555">
                                        <BsFillTrash3Fill title="Delete" className="inline-icon" />
                                    </button>
                                </div>
                            </div>
                            <p className="mt-1 text-xs text-gray-400">{streamDetails[stream?.ID]?.updated ? "Updated " + displayDate(ConvertUnixTimestampToDateString(streamDetails[stream.ID]?.updated)) : ""}  </p>

                            <p className="mt-1 text-sm text-gray-200 italic">{streamDetails[stream.ID]?.Description}</p>
                            <p class="mt-1 text-sm text-gray-400">
                                <span className="text-xs" id={"stream_" + index}>{stream.ID}</span><CopyToClipboard elId={"stream_" + index} />
                            </p>
                            {streamDetails[stream.ID]?.tags?.__SYS_JW_FileName?.Value ?
                                <button formMethod="dialog" formTarget="top" id="download" onClick={() => handleDownload(stream.ID)} className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-1">
                                    <FaFileDownload className="inline-icon" style={{ height: "1rem", width: "1rem" }} /> Download
                                </button>
                                : <></>}
                        </li>
                    })}
                </ul>
                : <span>No documents found</span>}
        </div>
    </div>
}